<template>
  <div>
    <bg-modal
      :value="isOpen"
      :body-scroll="false"
      :close-on-click-backdrop="false"
      :closable="!isSavingNote"
      @close="closeModal"
    >
      <bg-text tag="h4" size="heading-4">Catatan</bg-text>
      <bg-text size="body-2" class="mb-16"
        >Tambahkan catatan untuk tagihan ini</bg-text
      >

      <bg-skeleton
        v-if="isFetchingNote"
        width="100%"
        :height="72"
        style="margin-bottom: px"
      />

      <bg-textarea
        v-if="showInputNote"
        v-model="localNote"
        :max-char="300"
        :disabled="isSavingNote"
        placeholder="Tulis di sini"
      />

      <template v-slot:footer>
        <div class="ta-r">
          <bg-button
            v-show="!isSavingNote"
            class="mr-16"
            variant="tertiary"
            @click="closeModal"
            >Kembali</bg-button
          >
          <bg-button
            variant="primary"
            @click="handleSaveNote"
            :loading="isSavingNote"
            :disabled="isFetchingNote"
            >Simpan</bg-button
          >
        </div>
      </template>
    </bg-modal>

    <bg-modal
      v-model="isSuccessSaveNote"
      description="Note telah berhasil disimpan"
      desktop-size="sm"
      button-main-text="OK"
      @click-main-action="isSuccessSaveNote = false"
    />
  </div>
</template>

<script>
import { BgModal, BgSkeleton, BgButton, BgText, BgTextarea } from 'bangul-vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { ModalState } from '@admin_store/modules/note';

export default {
  name: 'NoteModal',

  components: {
    BgModal,
    BgSkeleton,
    BgButton,
    BgText,
    BgTextarea,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localNote: '',
      isSuccessSaveNote: false,
    };
  },

  computed: {
    ...mapState('note', ['note', 'modalState']),
    ...mapGetters('note', ['isAnyChanges']),

    isSavingNote() {
      return this.modalState === ModalState.SAVING_NOTE;
    },
    isFetchingNote() {
      return this.modalState === ModalState.FETCHING_NOTE;
    },
    showInputNote() {
      return this.modalState !== ModalState.FETCHING_NOTE;
    },
  },

  watch: {
    note(value) {
      this.localNote = value;
    },
    modalState(value) {
      switch (value) {
        case ModalState.NOTE_SAVED:
          this.isSuccessSaveNote = true;
          this.closeModal();
          break;
        default:
          break;
      }
    },
  },

  methods: {
    ...mapMutations('note', ['resetState']),
    ...mapActions('note', ['saveNote']),

    closeModal() {
      this.$emit('update:isOpen', false);
      this.resetState();
    },

    async handleSaveNote() {
      if (this.isAnyChanges(this.localNote)) {
        await this.saveNote(this.localNote);
      } else {
        this.closeModal();
      }
    },
  },
};
</script>
